<template>
  <div
    class="modal-bg"
    @click="openSideModal()"
  >
    <div @click.stop class="side-modal-wrapper">
      <div class="logo-wrapper">
        <p>Umra ziyoratini tashkil qilish va amalga oshirish bo‘yicha turfirmalar reytingi tizimi</p>
      </div>

      <div class="btns d-f gap-24 mt-a">
        <button
          @click="openSideModal()"
          class="danger-btn c-6"
        >
          <span>
            Bekor qilish
          </span>
        </button>

        <button
          class="action-btn c-6"
          @click="exitSystem()"
        >
          <span>
            Tizimdan chiqish
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'sideModalComponent',

    methods: {
      openSideModal() {
        this.$emit('openSideModal', false)
      },

      exitSystem() {
        this.$emit('exitSystem')
      }
    }
  }
</script>