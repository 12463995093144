import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'TourAgencies',
    component: () => import('../views/TourAgencies.vue'),
    meta: {auth: true}
  },
  {
    path: '/tour-agency-page/:id',
    name: 'TourAgency',
    component: () => import('../views/TourAgency.vue'),
    meta: {auth: true}
  },
  {
    path: '/tour-agency-edit/:id',
    name: 'TourAgencyEdit',
    component: () => import('../views/TourAgencyEdit.vue'),
    meta: {auth: true}
  },
  {
    path: '/tour-agency-create',
    name: 'TourAgencyCreate',
    component: () => import('../views/TourAgencyCreate.vue'),
    meta: {auth: true}
  },
  {
    path: '/applications',
    name: 'ApplicationsPage',
    component: () => import('../views/Applications.vue'),
    meta: {auth: true}
  },
  {
    path: '/applications/:id',
    name: 'ApplicationPage',
    component: () => import('../views/Application.vue'),
    meta: {auth: true}
  },
  {
    path: '/directory',
    name: 'DirectoryPage',
    component: () => import('../views/Directory.vue'),
    meta: {auth: true}
  },
  {
    path: '/settings',
    name: 'SettingsPage',
    component: () => import('../views/Settings.vue'),
    meta: {auth: true}
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import('../views/Login.vue'),
    meta: {auth: false}
  },
  {
    path: '/:pathMatch(.*)',
    name: 'PageNotFound',
    component: () => import('../views/PageNotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const access_token  = sessionStorage.getItem("userInfo")
  if (access_token && to && to.name == "LoginPage") {
    next({ path: '/'})
  }
  else if (!access_token && to.name != "LoginPage") {
    next({ path: '/login' })
  }
  else next();
})

export default router