<template>
  <div v-if="this.$route.meta.auth == true" class="default-layout">
    <sideModal
      v-if="sideModalOpen"
      @openSideModal="openSideModal"
      @exitSystem="exitSystem"
    />
    <sidebar
      @openSideModal="openSideModal"
    />
    <router-view/>
  </div>
  <div v-else class="secondary-layout">
    <router-view/>
  </div>
</template>

<script>
  import sidebar from './components/sidebar.vue'
  import sideModal from './components/sideModal.vue'

  export default {
    name: 'App',

    components: {
      sidebar,
      sideModal
    },

    data() {
      return {
        sideModalOpen: false
      }
    },

    methods: {
      openSideModal(state) {
        this.sideModalOpen = state
      },

      exitSystem() {
        sessionStorage.removeItem('userInfo')
        this.$router.go()
      }
    }
  }
</script>
