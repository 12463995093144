<template>
  <div class="sidebar">
    <div class="logo">
      <img src="../assets/logo.png" alt="">
    </div>
    <span style="color: #FFF; margin-top: 12px;">Turizm qo‘mitasi</span>
    <div class="divider-div"></div>

    <div class="btns">
      <a
        href="/"
        :class="homePage"
      >
        <img src="../assets/icons/plane.svg" alt="">
        <span>Turfirmalar</span>
      </a>
      <!-- <a
        href="/applications"
        :class="{active: activeRoute('applications')}"
      >
        <img src="../assets/icons/direct-inbox.svg" alt="">
        <span>Arizalar</span>
      </a>
      <a
        href="/directory"
        :class="{active: activeRoute('directory')}"
      >
        <img src="../assets/icons/document.svg" alt="">
        <span>Ma’lumotnoma</span>
      </a>
      <a
        href="/settings"
        :class="{active: activeRoute('settings')}"
      >
        <img src="../assets/icons/settings.svg" alt="">
        <span>Sozlamalar</span>
      </a> -->
    </div>

    <button
      class="profile-btn"
      @click="openSideModal()"
    >
      <img src="../assets/icons/user.svg" alt="">
      <span>
        admin
      </span>
    </button>
  </div>
</template>

<script>
  export default {
    name: 'sidebarComponent',

    computed: {
      homePage() {
        if(String(this.$route.href).includes('/tour-agency') || this.$route.href == '/') {
          return 'active'
        }
        else return ''
      },
    },

    methods: {
      openSideModal() {
        this.$emit('openSideModal', true)
      },

      activeRoute(route) {
        if(String(this.$route.href).includes('/'+route)) {
          return true
        }
        else return false
      }
    }
  }
</script>